import react from "react";
function Footer()
{
    return(
        <div className="container footermargin">
        <div className="row">
            <div className="col-lg-10 col-12 col-md-8 text order-md-1 order-sm-3 order-3">
                <span className="footer"><img src="images/logo.png" className="footerlogo" /> ThetaNet</span>
                <p className="copyright">All rights Reserved 2022</p>

            </div>
            <div className="col-lg-1 col-12 col-md-2 text order-md-1 order-sm-1 order-1">
                <span className="footerhead">Resources </span>
                <p className="copyright">Stake</p>
                <p className="copyright">PokerChip NFT</p>

            </div>
            <div className="col-lg-1 col-12 col-md-2 text order-md-1 order-sm-2 order-2">
                <span className="footerhead">Resources </span>
                <p className="copyright">Documentation</p>
            </div>
        </div>
    </div>
    )
}

export default Footer;
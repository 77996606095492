import react, { useEffect, useContext } from "react";
import { NavLink } from "react-router-dom";
import Web3Context from "../store/Web3-Context";

function myFunction() {
  var x = document.getElementById("myTopnav");
  if (x.className === "topnav") {
    // console.log('check');
    x.className += " responsive";
  } else {
    x.className = "topnav";
  }
}
function Header() {
  const web3Ctx = useContext(Web3Context);

  useEffect(() => {
    web3Ctx.checkIfWalletConnected();
  }, []);

  const shortenAddress = (str) => {
    return str.substring(0, 6) + "..." + str.substring(str.length - 4);
  };

  const connectWalletHandler = () => {
    if (web3Ctx.walletAddress) return;

    web3Ctx.connectWallet();
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-3 col-12 col-md-3 logomobile">
          <NavLink to="/">
            <img src="images/logo.png" className="logo" alt="Website-Logo" />
          </NavLink>
        </div>
        <div className="col-lg-9 col-md-9 col-md-9">
          <div className="topnav" id="myTopnav">
            <a href="#" className="address-btn">
              <button type="button" className="button w-100 p-3">
                0x...191a{" "}
              </button>
            </a>
            <NavLink to="/Tokencollection">Token 2021 Collection</NavLink>
            <NavLink to="/Nodestacking">Node Staking</NavLink>
            <NavLink to="/Gallery">Gallery</NavLink>
            <a className="hidden">
              <button className="button" onClick={connectWalletHandler}>
                {web3Ctx.walletAddress
                  ? shortenAddress(web3Ctx.walletAddress)
                  : "Connect"}
              </button>
            </a>
            <a href="#" className="icon" onClick={myFunction}>
              <i className="fa fa-bars " id="ba"></i>
              <i className="fa fa-times " id="cross"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Header;

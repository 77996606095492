import Web3Context from "./Web3-Context";
import React, { useReducer, useCallback, useState } from "react";
const { ethers } = require("ethers");

const theta = {
  chainId: `0x${Number(361).toString(16)}`,
  chainName: "Theta Mainnet",
  nativeCurrency: {
    name: "Theta Fuel",
    symbol: "TFUEL",
    decimals: 18,
  },
  rpcUrls: ["https://eth-rpc-api.thetatoken.org/rpc"],
  blockExplorerUrls: ["https://explorer.thetatoken.org/"],
};

const defaultWeb3State = {
  walletAddress: "",
  provider: "",
  signer: "",
};

const web3Reducer = (state, action) => {
  if (action.type === "WALLET_CONNECT") {
    console.log("Wallet Connect ACTION");

    const walletAddress = action.walletAddress;
    const provider = action.provider;
    const signer = action.signer;
    return {
      walletAddress: walletAddress,
      provider: provider,
      signer: signer,
    };
  }

  return defaultWeb3State;
};

const Web3Provider = (props) => {
  const [web3State, dispatchWeb3Action] = useReducer(
    web3Reducer,
    defaultWeb3State
  );

  const changeNetwork = async () => {
    try {
      if (!window.ethereum) throw new Error("No crypto wallet found");
      await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: [
          {
            ...theta,
          },
        ],
      });
    } catch (err) {
      console.log(err.message);
    }
  };

  const connectWalletHandler = async () => {
    try {
      const { ethereum } = window;

      // Get a Web3 instance for the wallet
      const accounts = await ethereum.request({
        method: "eth_requestAccounts",
      });

      const providerEthers = new ethers.providers.Web3Provider(ethereum); // Allows for interaction with ethereum nodes - read/write
      const signer = providerEthers.getSigner(); // Abstraction of the Ethereum Account which can be used to sign messages and transactions and send signed transactions

      if (window.ethereum.networkVersion !== 361) {
        changeNetwork();
      }

      dispatchWeb3Action({
        type: "WALLET_CONNECT",
        walletAddress: accounts[0],
        provider: providerEthers,
        signer: signer,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const checkIfWalletIsConnectedHandler = async () => {
    try {
      const { ethereum } = window;

      if (!ethereum) {
        return;
      }

      const accounts = await ethereum.request({ method: "eth_accounts" });

      if (window.ethereum.networkVersion !== 361) {
        await changeNetwork();
      }

      if (accounts.length !== 0) {
        const provider = new ethers.providers.Web3Provider(ethereum); // Allows for interaction with ethereum nodes - read/write
        const signer = provider.getSigner(); // Abstraction of the Ethereum Account which can be used to sign messages and transactions and send signed transactions

        dispatchWeb3Action({
          type: "WALLET_CONNECT",
          walletAddress: accounts[0],
          provider: provider,
          signer: signer,
        });
      } else {
        console.log("No authorized account found");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const web3Context = {
    walletAddress: web3State.walletAddress,
    provider: web3State.provider,
    signer: web3State.signer,
    connectWallet: connectWalletHandler,
    checkIfWalletConnected: checkIfWalletIsConnectedHandler,
  };

  return (
    <Web3Context.Provider value={web3Context}>
      {props.children}
    </Web3Context.Provider>
  );
};

export default Web3Provider;

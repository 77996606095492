import React from "react";
import { NavLink } from "react-router-dom";
function Homepage() {
  return (
    <div>
      <div className="layer ">
        <div className="container">
          <div className="banner text-center">
            <h1 className="heading">
              THETA & TFUEL
              <br /> STAKING
            </h1>
            <p className="paragraph">
              THETANET set out on a mission to help THETA guardian community
              with non-custodial digital staking services{" "}
            </p>
            <NavLink to="/Nodestacking">
              <button className="buttonbanner">
                Stake{" "}
                <span className="btnarrow">
                  <img src="images/arrow.png" alt="" />
                </span>
              </button>
            </NavLink>

            <div className="row margin"></div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="bannerr text-center">
          <div className="secheading">
            <h4 className="productheading">PRODUCTS</h4>
          </div>
          <div className="row margin">
            <div className="col-lg-6 col-md-6">
              <div className="boxbgg">
                <img src="images/icon1.png" className="iconwidth" />
                <p className="boxheading">Poker Chip</p>
                <p className="boxpara mt-4">
                  If we can do some stuff like that I can provide any
                  information and this site{" "}
                </p>
                <NavLink to="/Tokencollection" className="tc-none">
                  {" "}
                  <p className="stake mt-1">Buy</p>{" "}
                </NavLink>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="sboxbgg">
                <img src="images/icon2.png" className="iconwidth" />
                <p className="boxheading">Stake TFUEL or THETA</p>
                <p className="boxpara">
                  If we can do some stuff like that I can provide any
                  information and this site
                </p>
                <NavLink to="/Nodestacking" className="tc-none">
                  {" "}
                  <p className="stake">Stake</p>{" "}
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Homepage;

import React from "react";

function Nodestacking() {
  return (
    <div>
      <section className="section ">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-12">
              <h6 className="text-white tt-font-bold">THETA Staking </h6>
            </div>
            <div className="col-lg-6 col-12">
              <div className="braedcrumb-title">
                <p className="p-grey-color tt-font">
                  This Collection is made up of 6 unique designs
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-6">
              <div className="text-right only-desktop">
                <a
                  href="https://docs.thetatoken.org/docs/guardian-staking-process"
                  target="_blank"
                  className="nft-holder"
                >
                  View Instructions
                </a>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-12">
              <div className="border-bottom"></div>
            </div>
          </div>

          <div className="row align-items-center">
            <div className="col-lg-12 col-md-12 col-12">
              <div className="full-bar">
                <div className="row align-items-center">
                  <div className="col-lg-10 col-md-8 col-12">
                    <h6 className="tt-font text-white">STAKE THETA</h6>
                    <p className="grey-color tt-font m-b-0">
                      A minimum of 1,000 Theta are required to stake and earn
                      rewards.
                    </p>
                  </div>
                  <div className="col-lg-2 col-md-4 col-12">
                    <button
                      className="btn modal-form-button1 tt-font-bold border-none w-100 nft-holder"
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                    >
                      Stake THETA
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-12 col-md-12 col-12">
              <div className="full-bar">
                <div className="row align-items-center">
                  <div className="col-lg-10 col-md-8 col-12">
                    <h6 className="tt-font text-white">STAKE TFUEL</h6>
                    <p className="grey-color tt-font m-b-0">
                      A minimum of 10.000 TFUEL are required to stake and earn
                      rewards.
                    </p>
                  </div>
                  <div className="col-lg-2 col-md-4 col-12">
                    <button
                      className="btn yellow-form-button tt-font-bold border-none w-100 nft-holder"
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal2"
                    >
                      Stake TFUEL
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-12 col-md-12 col-12">
            <div className="full-bar">
              <div className="row align-items-center">
                <div className="col-lg-10 col-md-8 col-12">
                  <h6 className="tt-font text-white">VIP STAKING</h6>
                  <p className="grey-color tt-font m-b-0">
                    Please email 1) NFT smart contract address 2) If you are
                    staking THETA or TFUEL
                  </p>
                </div>
                <div className="col-lg-2 col-md-4 col-12 text-center">
                  <a
                    href="#"
                    class="tt-font-bold yellow-color tc-none"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal3"
                  >
                    Request Summary
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        className="modal fade"
        id="exampleModal3"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Please email the following information to{" "}
                <a href="mailto:THETANET@protonmail.com">
                  THETANET@protonmail.com
                </a>
              </h5>
              <button
                type="button"
                className="btn-close text-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                X
              </button>
            </div>
            <div className="modal-body">
              <h6>1. What are you wanting to stake TFUEL or THETA or Both</h6>
              <h6>
                2. THETANET NFT smart contract address or Address of wallet that
                hold the NFT
              </h6>
              <h6>3. Email address</h6>
              <h6>
                4. May be required a very small amount of TFUEL (0.1) from
                wallet address that holds NFT to confirm ownership
              </h6>

              <div className="text-center">
                <button className="buy-btn btn tt-font-bold">
                  Buy PokerChip / Keychain
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h6 className="modal-title" id="exampleModalLabel">
                THETA - Guardian Community Staking Summary{" "}
              </h6>
              <button
                type="button"
                className="btn-close text-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                X
              </button>
            </div>
            <div className="modal-body">
              <p className="tt-font grey-color">
                Staking rewards are subject to a 2% commission.)
              </p>
              <div className="set-text mt-5 mb-4">
                0x060cd7d827D607d000F9639665D64026cd94f21Ba9a5c71a228b82a5dee1f621f5f12f0e12e13df7d7fd4096a76bc43acb94c2bb31b3a9ddcf12f46315ba27a3e34bf6b2987e8ed54459062fa6fb434276137f4adfb54dba75f12646c9ac25ad364ebe743810917e955f005eaec668315510f0e817c2a862feb0a12a3938646aa129d782197a3301d82d2179f9163a65eb214d83107d4d7c2c25b649e65db336242d313ce2c83babf45ee6ed3f16473133d1efde25b386c39d897fe944feb38b945b573d318c134d2eea8f80eef1055cbdeb9cd1c2ebe35171994383898d184628d4a77901
              </div>

              <div className="text-center mt-3">
                <button className="buy-btn btn tt-font-bold w-100 p-3">
                  Copy Summary{" "}
                </button>
                <button type="button" className="nft-holder mt-3">
                  How Theta staking works
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="exampleModal2"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h6 className="modal-title" id="exampleModalLabel">
                TFUEL - Guardian Community Staking Summary
              </h6>
              <button
                type="button"
                className="btn-close text-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                X
              </button>
            </div>
            <div className="modal-body">
              <p className="tt-font grey-color">
                Staking rewards are subject to a 2% commission.)
              </p>
              <div className="set-text mt-5 mb-4">
                0xf8090525F028c89C721e45c6495Dc5DB130f3c6Ab283df9decfd136fa943f29031dfb59ce7ac3d1bc98c208d922dda28807e72cfc905c6ae71544cc56e309a6cdfcc963389e237600fce4368fb1b465d233abc171a21128be8113ab2e8af6890a4c8ce59ea24692e99ca0af603a3543e937ed9ef17ee4299c3c4bffc056a6cbdc9b9d8c6551119687894fcc8efbf9fa000c0985e9ddfe8833f34713b65908a7a8b73076a2dbe9471a406be16015b23464b482ee635903973b08a94db2388d45641f0c5a546410981ec6d2d131296a058f287974cf9ef2c864601041072a35ae56fae22b30117085589ed7365374332e98dd4e66fc62d42aba9996f9db364551dbdf9bfb2ce
              </div>

              <div className="text-center mt-3">
                <button className="buy-btn btn tt-font-bold w-100 p-3">
                  Copy Summary{" "}
                </button>
                <button type="button" className="nft-holder mt-3">
                  How Theta staking works
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Nodestacking;

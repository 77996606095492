import react from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Homepage from "./components/Homepage";

function App() {
  return (
    <div>
      <Header></Header>
      <Homepage></Homepage>
      <Footer></Footer>
    </div>
    
  );
}

export default App;

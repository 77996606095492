import React from "react";
function Gallery()
{
    return(
        <section className="section ">
            <div className="container">


                <div className="row">
                    <div className="col-lg-12 col-12 mt-1">
                        <h6 className="text-white tt-font-bold mb-5">Gallery </h6>
                    </div>
                </div>

                <div className="row align-items-center">
                    <div className="col-lg-12 col-12 mt-1">
                        <img src="images/gallery-1.png" className="w-100"/>
                    </div>
                    </div>
                    <div className="row align-items-center mt-4">
                    <div className="col-lg-4 col-12 mt-1">
                        <img src="images/gallery-2.png" className="w-100 mb-4"/>
                        <img src="images/gallery-3.png" className="w-100"/>
                    </div>
                    <div className="col-lg-8 col-12 mt-1">
                        <img src="images/gallery-4.png" className="w-100"/>
                    </div>
                </div>
                <div className="row align-items-center mt-4">
                    <div className="col-lg-4 col-12 mt-1">
                        <img src="images/gallery-5.png" className="w-100"/>
                        </div>
                        <div className="col-lg-4 col-12 mt-1">
                            <img src="images/gallery-6.png" className="w-100"/>
                            </div>
                            <div className="col-lg-4 col-12 mt-1">
                                <img src="images/gallery-7.png" className="w-100"/>
                                </div>
                    </div>

                    <div className="row align-items-center mt-4">
                        <div className="col-lg-4 col-12 mt-1">
                            <img src="images/gallery-8.png" className="w-100"/>
                            </div>
                            <div className="col-lg-4 col-12 mt-1">
                                <img src="images/gallery-9.png" className="w-100"/>
                                </div>
                                <div className="col-lg-4 col-12 mt-1">
                                    <img src="images/gallery-10.png" className="w-100"/>
                                    </div>
                        </div>

                        <div className="row align-items-center mt-4">
                            <div className="col-lg-4 col-12 mt-1">
                                <img src="images/gallery-11.png" className="w-100" />
                                </div>
                                <div className="col-lg-4 col-12 mt-1">
                                    <img src="images/gallery-12.png" className="w-100 mb-4"/>
                                    <img src="images/gallery-14.png" className="w-100" />
                                    </div>
                                    <div className="col-lg-4 col-12 mt-1">
                                        <img src="images/gallery-13.png" className="w-100 mb-4"/>
                                        <img src="images/gallery-15.png" className="w-100" />
                                        </div>
                            </div>
            </div>

        </section>
    )
}
export default Gallery;
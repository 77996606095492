import React from "react";

const ItemContext = React.createContext({
  tDropCount: 0,
  tFuelCount: 0,
  godzillaCount: 0,
  blackCount: 0,
  blueCount: 0,
  darkCount: 0,
  tDropCountK: 0,
  tFuelCountK: 0,
  godzillaCountK: 0,
  blackCountK: 0,
  blueCountK: 0,
  darkCountK: 0,
  incrementTdrop: () => {},
  decrementTdrop: () => {},
  incrementTfuel: () => {},
  decrementTfuel: () => {},
  incrementGodzilla: () => {},
  decrementGodzilla: () => {},
  incrementBlack: () => {},
  decrementBlack: () => {},
  incrementBlue: () => {},
  decrementBlue: () => {},
  incrementDark: () => {},
  decrementDark: () => {},
  incrementTdropK: () => {},
  decrementTdropK: () => {},
  incrementTfuelK: () => {},
  decrementTfuelK: () => {},
  incrementGodzillaK: () => {},
  decrementGodzillaK: () => {},
  incrementBlackK: () => {},
  decrementBlackK: () => {},
  incrementBlueK: () => {},
  decrementBlueK: () => {},
  incrementDarkK: () => {},
  decrementDarkK: () => {},
});

export default ItemContext;

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import {
  Homepage,
  Footer,
  Header,
  Tokencollection,
  Nodestacking,
  Gallery,
} from "./components";
import Web3Provider from "./store/Web3Provider";
import ItemProvider from "./store/ItemProvider";

ReactDOM.render(
  <Web3Provider>
    <ItemProvider>
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/Tokencollection" element={<Tokencollection />} />
          <Route path="/Nodestacking" element={<Nodestacking />} />
          <Route path="/Gallery" element={<Gallery />} />
        </Routes>
        <Footer />
      </Router>
    </ItemProvider>
  </Web3Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, { useState } from "react";
import ItemContext from "./Item-Context";

const Web3Provider = (props) => {
  const [tDropQuantity, setTdropsQuantity] = useState(0);
  const [tFuelQuantity, setTfuelQuantity] = useState(0);
  const [godzillaQuantity, setGodzillaQuantity] = useState(0);
  const [blackQuantity, setBlackQuantity] = useState(0);
  const [blueQuantity, setBlueQuantity] = useState(0);
  const [darkQuantity, setDarkQuantity] = useState(0);
  const [tDropQuantityK, setTdropsQuantityK] = useState(0);
  const [tFuelQuantityK, setTfuelQuantityK] = useState(0);
  const [godzillaQuantityK, setGodzillaQuantityK] = useState(0);
  const [blackQuantityK, setBlackQuantityK] = useState(0);
  const [blueQuantityK, setBlueQuantityK] = useState(0);
  const [darkQuantityK, setDarkQuantityK] = useState(0);

  const incrementTdrop = () => {
    setTdropsQuantity(tDropQuantity + 1);
  };

  const decrementTdrop = () => {
    if (tDropQuantity === 0) {
      return;
    }

    setTdropsQuantity(tDropQuantity - 1);
  };
  const incrementTfuel = () => {
    setTfuelQuantity(tFuelQuantity + 1);
  };

  const decrementTfuel = () => {
    if (tFuelQuantity === 0) {
      return;
    }

    setTfuelQuantity(tFuelQuantity - 1);
  };

  const incrementGodzilla = () => {
    setGodzillaQuantity(godzillaQuantity + 1);
  };

  const decrementGodzilla = () => {
    if (godzillaQuantity === 0) {
      return;
    }

    setGodzillaQuantity(godzillaQuantity - 1);
  };

  const incrementBlack = () => {
    setBlackQuantity(blackQuantity + 1);
  };

  const decrementBlack = () => {
    if (blackQuantity === 0) {
      return;
    }

    setBlackQuantity(blackQuantity - 1);
  };

  const incrementBlue = () => {
    setBlueQuantity(blueQuantity + 1);
  };

  const decrementBlue = () => {
    if (blueQuantity === 0) {
      return;
    }

    setBlueQuantity(blueQuantity - 1);
  };

  const incrementDark = () => {
    setDarkQuantity(darkQuantity + 1);
    console.log(darkQuantity);
  };

  const decrementDark = () => {
    if (darkQuantity === 0) {
      return;
    }

    setDarkQuantity(darkQuantity - 1);
  };

  ///// KEYCHAINS ///////
  const incrementTdropK = () => {
    setTdropsQuantityK(tDropQuantityK + 1);
  };

  const decrementTdropK = () => {
    if (tDropQuantityK === 0) {
      return;
    }

    setTdropsQuantityK(tDropQuantityK - 1);
  };
  const incrementTfuelK = () => {
    setTfuelQuantityK(tFuelQuantityK + 1);
  };

  const decrementTfuelK = () => {
    if (tFuelQuantityK === 0) {
      return;
    }

    setTfuelQuantityK(tFuelQuantityK - 1);
  };

  const incrementGodzillaK = () => {
    setGodzillaQuantityK(godzillaQuantityK + 1);
  };

  const decrementGodzillaK = () => {
    if (godzillaQuantityK === 0) {
      return;
    }

    setGodzillaQuantityK(godzillaQuantityK - 1);
  };

  const incrementBlackK = () => {
    setBlackQuantityK(blackQuantityK + 1);
  };

  const decrementBlackK = () => {
    if (blackQuantityK === 0) {
      return;
    }

    setBlackQuantityK(blackQuantityK - 1);
  };

  const incrementBlueK = () => {
    setBlueQuantityK(blueQuantityK + 1);
  };

  const decrementBlueK = () => {
    if (blueQuantityK === 0) {
      return;
    }

    setBlueQuantityK(blueQuantityK - 1);
  };

  const incrementDarkK = () => {
    setDarkQuantityK(darkQuantityK + 1);
  };

  const decrementDarkK = () => {
    if (darkQuantityK === 0) {
      return;
    }

    setDarkQuantityK(darkQuantityK - 1);
  };

  const itemContext = {
    tDropCount: tDropQuantity,
    tFuelCount: tFuelQuantity,
    godzillaCount: godzillaQuantity,
    blackCount: blackQuantity,
    blueCount: blueQuantity,
    darkCount: darkQuantity,
    incrementTdrop: incrementTdrop,
    decrementTdrop: decrementTdrop,
    incrementTfuel: incrementTfuel,
    decrementTfuel: decrementTfuel,
    incrementGodzilla: incrementGodzilla,
    decrementGodzilla: decrementGodzilla,
    incrementBlack: incrementBlack,
    decrementBlack: decrementBlack,
    incrementBlue: incrementBlue,
    decrementBlue: decrementBlue,
    incrementDark: incrementDark,
    decrementDark: decrementDark,
    tDropCountK: tDropQuantityK,
    tFuelCountK: tFuelQuantityK,
    godzillaCountK: godzillaQuantityK,
    blackCountK: blackQuantityK,
    blueCountK: blueQuantityK,
    darkCountK: darkQuantityK,
    incrementTdropK: incrementTdropK,
    decrementTdropK: decrementTdropK,
    incrementTfuelK: incrementTfuelK,
    decrementTfuelK: decrementTfuelK,
    incrementGodzillaK: incrementGodzillaK,
    decrementGodzillaK: decrementGodzillaK,
    incrementBlackK: incrementBlackK,
    decrementBlackK: decrementBlackK,
    incrementBlueK: incrementBlueK,
    decrementBlueK: decrementBlueK,
    incrementDarkK: incrementDarkK,
    decrementDarkK: decrementDarkK,
  };

  return (
    <ItemContext.Provider value={itemContext}>
      {props.children}
    </ItemContext.Provider>
  );
};

export default Web3Provider;

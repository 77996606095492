import React, { useState, useContext, useEffect } from "react";
import ItemContext from "../store/Item-Context";
import Web3Context from "../store/Web3-Context";
import CircularProgress from "@mui/material/CircularProgress";
const { ethers } = require("ethers");
const axios = require("axios");

function Tokencollection() {
  const [sort, setSort] = useState("All");
  const [item, setItem] = useState("TDROP");
  const [buyOption, setBuyOption] = useState("Pokerchips");
  const [postalAddress, setPostalAddress] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [isPurchase, setIsPurchase] = useState(true);
  const [postagePrice, setPostagePrice] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [price, setPrice] = useState(0);
  const itemCtx = useContext(ItemContext);
  const web3Ctx = useContext(Web3Context);

  const itemQuantityArray = [
    itemCtx.tDropCount,
    itemCtx.tFuelCount,
    itemCtx.godzillaCount,
    itemCtx.blackCount,
    itemCtx.blueCount,
    itemCtx.darkCount,
    itemCtx.tDropCountK,
    itemCtx.tFuelCountK,
    itemCtx.godzillaCountK,
    itemCtx.blackCountK,
    itemCtx.blueCountK,
    itemCtx.darkCountK,
  ];

  const itemQuantitySum = itemQuantityArray.reduce(function (a, b) {
    return a + b;
  }, 0);

  useEffect(() => {
    if (itemQuantitySum) {
      let price;
      let postagePrice;

      if (itemQuantitySum <= 50) {
        price = 15;
        postagePrice = 100;
      } else if (itemQuantitySum >= 51 && itemQuantitySum <= 100) {
        price = 12;
        postagePrice = 100;
      } else {
        price = 10;
        postagePrice = 120;
      }
      setPrice(itemQuantitySum * price + postagePrice);
      setPostagePrice(postagePrice);
    }

    return () => {
      setPrice(0);
    };
  }, [
    itemCtx.tDropCount,
    itemCtx.tFuelCount,
    itemCtx.godzillaCount,
    itemCtx.blackCount,
    itemCtx.blueCount,
    itemCtx.darkCount,
    itemQuantitySum,
  ]);

  const addHandler = () => {
    if (isLoading) {
      return;
    }

    switch (item) {
      case "TDROP":
        if (buyOption === "Pokerchips") {
          itemCtx.incrementTdrop();
        } else {
          itemCtx.incrementTdropK();
        }

        break;
      case "TFUEL":
        if (buyOption === "Pokerchips") {
          itemCtx.incrementTfuel();
        } else {
          itemCtx.incrementTfuelK();
        }

        break;
      case "GODZILLA":
        if (buyOption === "Pokerchips") {
          itemCtx.incrementGodzilla();
        } else {
          itemCtx.incrementGodzillaK();
        }
        break;
      case "BLACK":
        if (buyOption === "Pokerchips") {
          itemCtx.incrementBlack();
        } else {
          itemCtx.incrementBlackK();
        }

        break;
      case "BLUE":
        if (buyOption === "Pokerchips") {
          itemCtx.incrementBlue();
        } else {
          itemCtx.incrementBlueK();
        }

        break;
      case "DARK":
        if (buyOption === "Pokerchips") {
          itemCtx.incrementDark();
        } else {
          itemCtx.incrementDarkK();
        }

        break;
      default:
        break;
    }
  };

  const removeHandler = () => {
    if (isLoading) {
      return;
    }
    switch (item) {
      case "TDROP":
        if (buyOption === "Pokerchips") {
          itemCtx.decrementTdrop();
        } else {
          itemCtx.decrementTdropK();
        }

        break;
      case "TFUEL":
        if (buyOption === "Pokerchips") {
          itemCtx.decrementTfuel();
        } else {
          itemCtx.decrementTfuelK();
        }

        break;
      case "GODZILLA":
        if (buyOption === "Pokerchips") {
          itemCtx.decrementGodzilla();
        } else {
          itemCtx.decrementGodzillaK();
        }

        break;
      case "BLACK":
        if (buyOption === "Pokerchips") {
          itemCtx.decrementBlack();
        } else {
          itemCtx.decrementBlackK();
        }

        break;
      case "BLUE":
        if (buyOption === "Pokerchips") {
          itemCtx.decrementBlue();
        } else {
          itemCtx.decrementBlueK();
        }

        break;
      case "DARK":
        if (buyOption === "Pokerchips") {
          itemCtx.decrementDark();
        } else {
          itemCtx.decrementDarkK();
        }

        break;
      default:
        break;
    }
  };

  const confirmHandler = async (e) => {
    e.preventDefault();

    if (!web3Ctx.walletAddress) {
      alert("Please connect to Metamask to purchase items.");
      return;
    }

    if (!postalAddress) {
      alert("Please enter your postal address.");
      return;
    }

    if (!price) {
      alert("Please add items.");
      return;
    }

    try {
      setIsLoading(true);
      const tx = await web3Ctx.signer.sendTransaction({
        to: "0x110df579910b39e8a2924798b59e41216a0a7adb",
        value: ethers.utils.parseEther(price.toString()),
      });
      await tx.wait();
      console.log(tx.hash);
      let formData = new FormData();
      formData.append("Postal Address", postalAddress); //append the values with key, value pair
      formData.append("Email Address", emailAddress);
      formData.append("TXN Hash", tx.hash);
      formData.append("TDrop Count Pokerchip", itemCtx.tDropCount);
      formData.append("TFuel Count Pokerchip", itemCtx.tFuelCount);
      formData.append("Godzilla Count Pokerchip", itemCtx.godzillaCount);
      formData.append("Black Count Pokerchip", itemCtx.blackCount);
      formData.append("Blue Count Pokerchip", itemCtx.blueCount);
      formData.append("Dark Count Pokerchip", itemCtx.darkCount);
      formData.append("TDrop Count KeyChain", itemCtx.tDropCountK);
      formData.append("TFuel Count KeyChain", itemCtx.tFuelCountK);
      formData.append("Godzilla Count KeyChain", itemCtx.godzillaCountK);
      formData.append("Black Count KeyChain", itemCtx.blackCountK);
      formData.append("Blue Count KeyChain", itemCtx.blueCountK);
      formData.append("Dark Count KeyChain", itemCtx.darkCountK);

      var options = {
        method: "POST",
        url: "https://formspree.io/f/xwkyllek",
        headers: {
          "content-type": "application/json",
        },
        data: formData,
      };

      axios
        .request(options)
        .then(function (response) {
          console.log(response.data);
          setIsLoading(false);
        })
        .catch(function (error) {
          console.error(error);

          setIsLoading(false);
        });
    } catch (e) {
      console.log(e);
      setIsLoading(false);
      if (e.code === -32603) {
        alert("Insufficient TFUEL Balance");
      }
    }
  };

  const togglePurchase = (value) => {
    setIsPurchase(value);
  };

  console.log(isPurchase);

  const renderPrices = () => {
    if (itemQuantitySum) {
      if (itemQuantitySum <= 50) {
        return 15;
      } else if (itemQuantitySum >= 51 && itemQuantitySum <= 100) {
        return 12;
      } else {
        return 10;
      }
    }
  };

  return (
    <div>
      <section className="section ">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-12">
              <h6 className="text-white tt-font-bold">
                THETANET Guardian 2021 Collections{" "}
              </h6>
            </div>
            <div className="col-lg-6 col-12">
              <div className="braedcrumb-title">
                <p className="p-grey-color tt-font">
                  This Collection is made up of 6 unique designs
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-6">
              <div className="text-right only-desktop">
                <button
                  type="button"
                  className="nft-holder"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  NFT holder Benefits
                </button>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-12">
              <div className="border-bottom"></div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-12">
              <p className="p-grey-color font-size-10 mt-4 mb-1">Sort By</p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-12">
              <div className="form-group ln-0">
                <select
                  name=""
                  id=""
                  className="form-select form-select-md bg-dark text-white mb-3 select-input tt-font"
                  onChange={(e) => {
                    setSort(e.target.value);
                  }}
                >
                  <option value="All">All</option>
                  <option value="PokerChips">Poker Chips</option>
                  <option value="KeyChains">Key Chains</option>
                </select>
              </div>
            </div>
            <div className="only-mobile col-6">
              <p className="upper-p bg-dark tt-font">891/1200 Minted</p>
            </div>
            <div className="col-lg-8 col-6">
              <div className="text-right only-mobile">
                <button
                  type="button"
                  className="nft-holder"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  NFT holder Benefits
                </button>
              </div>
              <div className="text-right buy-poker-button">
                <button
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal2"
                  className="buy-btn btn tt-font-bold"
                >
                  Buy PokerChip / Keychain
                </button>
              </div>
            </div>
          </div>

          <div className="row align-items-center">
            {(sort === "All" || sort === "PokerChips") && (
              <div className="col-lg-4">
                <div className="boxes">
                  <img src="images/boxes-img-1.png" alt="" />

                  <div className="d-flex p-25 bg-dark">
                    <div className="col-lg-6 col-6">
                      <h6 className="text-white tt-font">TDROP</h6>
                    </div>
                    <div className="col-lg-6 col-6 text-right">
                      <h6 className="text-white">0x..93293</h6>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {(sort === "All" || sort === "KeyChains") && (
              <div className="col-lg-4">
                <div className="boxes">
                  <img src="images/boxes-img-2.png" alt="" />

                  <div className="d-flex p-25 bg-dark">
                    <div className="col-lg-6 col-6">
                      <h6 className="text-white tt-font">DARK</h6>
                    </div>
                    <div className="col-lg-6 col-6 text-right">
                      <h6 className="text-white">0x..93293</h6>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {(sort === "All" || sort === "PokerChips") && (
              <div className="col-lg-4">
                <div className="boxes">
                  <img src="images/boxes-img-3.png" alt="" />

                  <div className="d-flex p-25 bg-dark">
                    <div className="col-lg-6 col-6">
                      <h6 className="text-white tt-font">TFUEL</h6>
                    </div>
                    <div className="col-lg-6 col-6 text-right">
                      <h6 className="text-white">0x..93293</h6>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {(sort === "All" || sort === "PokerChips") && (
              <div className="col-lg-4">
                <div className="boxes">
                  <img src="images/boxes-img-4.png" alt="" />

                  <div className="d-flex p-25 bg-dark">
                    <div className="col-lg-6 col-6">
                      <h6 className="text-white tt-font">GODZILLA</h6>
                    </div>
                    <div className="col-lg-6 col-6 text-right">
                      <h6 className="text-white">0x..93293</h6>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {(sort === "All" || sort === "PokerChips") && (
              <div className="col-lg-4">
                <div className="boxes">
                  <img src="images/boxes-img-5.png" alt="" />

                  <div className="d-flex p-25 bg-dark">
                    <div className="col-lg-6 col-6">
                      <h6 className="text-white tt-font">BLACK</h6>
                    </div>
                    <div className="col-lg-6 col-6 text-right">
                      <h6 className="text-white">0x..93293</h6>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {(sort === "All" || sort === "PokerChips") && (
              <div className="col-lg-4">
                <div className="boxes">
                  <img src="images/boxes-img-6.png" alt="" />

                  <div className="d-flex p-25 bg-dark">
                    <div className="col-lg-6 col-6">
                      <h6 className="text-white tt-font">BLUE</h6>
                    </div>
                    <div className="col-lg-6 col-6 text-right">
                      <h6 className="text-white">0x..93293</h6>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>

      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                NFT holder benefits{" "}
              </h5>
              <button
                type="button"
                className="btn-close text-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                X
              </button>
            </div>
            <div className="modal-body">
              <h6>What is VIP_NFT</h6>
              <p className="f-14">
                THETANET_TOKEN_2021 NFT's dropped on Thetaboad.io 15/12/2021.
                The holders of any of the NFT collection are able to benefit
                from Free staking of their THETA & TFUEL. They are also entitled
                to 30% discount to any product on this site.
              </p>
              <h6>THETANET_Guardian_TOKEN_2021 NFT</h6>
              <p className="f-14">
                This Collection has 6 work designs and each design has a mintage
                limit of 200. Each physical CHIP is individually serial numbered
                totalling 1200 CHIPS if all contracts are minted.
              </p>
              <p className="f-14">
                The first holder of these NFT's are given the first opportunity
                to REDEEM the Physical Serial Numbered CHIP as well as 6
                non-serial numbered CHIPS or Key Rings.
              </p>
              <h6>Eligible NFT Contracts:</h6>
              <p className="f-14">
                1. THETANET_Guardian_GODZILLA_2021 <br />
                2. THETANET_Guardian_DARK_2021 <br />
                3. THETANET_Guardian_BLUE_2021 <br />
                4. THETANET_Guardian_TFUEL_2021 <br />
                5. THETANET_Guardian_TDROP_2021 <br />
                6. THETANET_Guardian_BLACK_2021 <br />
              </p>
              <h6>NFT Holder Benefits</h6>
              <p className="f-14">
                If you are the holder of one of these NFT's congratulations! you
                can benefit from the following:
              </p>
              <p className="f-14">
                {" "}
                <i className="fa fa-circle set-i"></i> Holder gets FREE VIP
                staking of their THETA and TFUEL for 12 months <br />
                <i className="fa fa-circle set-i"></i> Holder can claim this NFT
                in its physical form individualised by serial number (Free
                Shipping) <br />
                <i className="fa fa-circle set-i"></i> Holder can claim 6 x
                non-serial numbered THETANET CHIPS or Key Rings (Free Shipping){" "}
                <br />
                <i className="fa fa-circle set-i"></i> Holder of this NFT has
                lifetime exclusive pricing on products and shipping
              </p>
              <h6>Conditions</h6>
              <p className="f-14">
                <i className="fa fa-circle set-i"></i> Once chip has been
                claimed it cannot be claimed again (Check TOKEN Claim Chart to
                find what chips have been claimed) <br />
                <i className="fa fa-circle set-i"></i> Maximum of 500,000 TFUEL
                can be staked per NFT <br />
                <i className="fa fa-circle set-i"></i> 3 year expiration date of
                staking offer from date of mintage for 2021 Editions <br />
              </p>
              <div className="text-center">
                <button className="buy-btn btn tt-font-bold">
                  Buy PokerChip / Keychain
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="exampleModal2"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title"
                id="exampleModalLabel"
                style={{ cursor: "pointer" }}
                onClick={() => togglePurchase(true)}
              >
                Purchase{" "}
              </h5>
              <h5
                className="modal-title"
                id="exampleModalLabel"
                style={{ paddingLeft: "4rem", cursor: "pointer" }}
                onClick={() => togglePurchase(false)}
              >
                {" "}
                Q&A{" "}
              </h5>

              <button
                type="button"
                className="btn-close text-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                X
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                {isPurchase && (
                  <>
                    <div className="col-lg-6  col-md-6 col-6">
                      <div className="form-group">
                        <div className="row">
                          <div className="col-lg-12">
                            <label className="label-modal tt-font">
                              Option
                            </label>
                          </div>
                          <div className="col-lg-6 col-md-6 col-6 p-0">
                            <input
                              type="button"
                              className={
                                buyOption === "Pokerchips"
                                  ? "btn modal-form-button1 w-100 left-br tt-font"
                                  : "btn modal-form-button2 w-100 right-br bg-none tt-font"
                              }
                              name=""
                              value="Pokerchips"
                              onClick={(e) => {
                                setBuyOption(e.target.value);
                              }}
                            />
                          </div>
                          <div className="col-lg-6  col-md-6 col-6 p-0">
                            <input
                              type="button"
                              className={
                                buyOption === "Keychain"
                                  ? "btn modal-form-button1 w-100 left-br tt-font"
                                  : "btn modal-form-button2 w-100 right-br bg-none tt-font"
                              }
                              name=""
                              value="Keychain"
                              onClick={(e) => {
                                setBuyOption(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6  col-md-6 col-6">
                      <div className="form-group">
                        <div className="row">
                          <div className="col-lg-12">
                            <label className="label-modal">Select</label>
                          </div>
                        </div>

                        <select
                          name=""
                          id=""
                          className="form-select form-select-md bg-dark text-white mb-3 select-input tt-font"
                          onChange={(e) => {
                            setItem(e.target.value);
                          }}
                        >
                          <option value="TDROP">TDROP</option>

                          <option value="TFUEL">TFUEL</option>

                          <option value="GODZILLA">GODZILLA</option>

                          <option value="BLACK">BLACK</option>

                          <option value="BLUE">BLUE</option>

                          <option value="DARK">DARK</option>
                        </select>
                      </div>
                    </div>

                    {!isLoading && (
                      <div className="col-lg-12 mt-4 mb-3">
                        <button
                          className="nft-holder tc-none"
                          style={{ cursor: "pointer" }}
                          onClick={addHandler}
                        >
                          Add
                        </button>
                        <br />
                        <button
                          className="nft-holder tc-none"
                          style={{ cursor: "pointer" }}
                          onClick={removeHandler}
                        >
                          Remove
                        </button>
                      </div>
                    )}
                    <div className="col-lg-3 col-md-3 col-3">
                      <p className="tt-font grey-color">OPTION</p>
                    </div>
                    <div className="col-lg-3 col-md-3 col-3">
                      <p className="tt-font grey-color">ITEM</p>
                    </div>
                    <div className="col-lg-3 col-md-3 col-3 text-right">
                      <p className="tt-font grey-color">QUANTITY</p>
                    </div>
                    <div className="col-lg-3 col-md-3 col-3 text-right">
                      <p className="tt-font grey-color">COST</p>
                    </div>

                    {itemCtx.tDropCount > 0 && (
                      <>
                        <div className="col-lg-3 col-md-3 col-3">
                          <p className="tt-font">Pokerchip:</p>
                        </div>
                        <div className="col-lg-3 col-md-3 col-3">
                          <p className="tt-font">Tdrop</p>
                        </div>
                        <div className="col-lg-3 col-md-3 col-3 text-right">
                          <p className="tt-font">x{itemCtx.tDropCount}</p>
                        </div>
                        <div className="col-lg-3 col-md-3 col-3 text-right">
                          <p className="tt-font">{renderPrices()} TFUEL</p>
                        </div>
                        <div className="col-lg-12 col-md-12 col-12">
                          <div className="grey-border"></div>
                        </div>
                      </>
                    )}

                    {itemCtx.tFuelCount > 0 && (
                      <>
                        <div className="col-lg-3 col-md-3 col-3">
                          <p className="tt-font">Pokerchip:</p>
                        </div>
                        <div className="col-lg-3 col-md-3 col-3">
                          <p className="tt-font">Tfuel</p>
                        </div>
                        <div className="col-lg-3 col-md-3 col-3 text-right">
                          <p className="tt-font">x{itemCtx.tFuelCount}</p>
                        </div>
                        <div className="col-lg-3 col-md-3 col-3 text-right">
                          <p className="tt-font">{renderPrices()} TFUEL</p>
                        </div>
                        <div className="col-lg-12 col-md-12 col-12">
                          <div className="grey-border"></div>
                        </div>
                      </>
                    )}

                    {itemCtx.godzillaCount > 0 && (
                      <>
                        <div className="col-lg-3 col-md-3 col-3">
                          <p className="tt-font">Pokerchip:</p>
                        </div>
                        <div className="col-lg-3 col-md-3 col-3">
                          <p className="tt-font">Godzilla</p>
                        </div>
                        <div className="col-lg-3 col-md-3 col-3 text-right">
                          <p className="tt-font">x{itemCtx.godzillaCount}</p>
                        </div>
                        <div className="col-lg-3 col-md-3 col-3 text-right">
                          <p className="tt-font">{renderPrices()} TFUEL</p>
                        </div>
                        <div className="col-lg-12 col-md-12 col-12">
                          <div className="grey-border"></div>
                        </div>
                      </>
                    )}

                    {itemCtx.blackCount > 0 && (
                      <>
                        <div className="col-lg-3 col-md-3 col-3">
                          <p className="tt-font">Pokerchip:</p>
                        </div>
                        <div className="col-lg-3 col-md-3 col-3">
                          <p className="tt-font">Black</p>
                        </div>
                        <div className="col-lg-3 col-md-3 col-3 text-right">
                          <p className="tt-font">x{itemCtx.blackCount}</p>
                        </div>
                        <div className="col-lg-3 col-md-3 col-3 text-right">
                          <p className="tt-font">{renderPrices()} TFUEL</p>
                        </div>
                        <div className="col-lg-12 col-md-12 col-12">
                          <div className="grey-border"></div>
                        </div>
                      </>
                    )}
                    {itemCtx.blueCount > 0 && (
                      <>
                        <div className="col-lg-3 col-md-3 col-3">
                          <p className="tt-font">Pokerchip:</p>
                        </div>
                        <div className="col-lg-3 col-md-3 col-3">
                          <p className="tt-font">Blue</p>
                        </div>
                        <div className="col-lg-3 col-md-3 col-3 text-right">
                          <p className="tt-font">x{itemCtx.blueCount}</p>
                        </div>
                        <div className="col-lg-3 col-md-3 col-3 text-right">
                          <p className="tt-font">{renderPrices()}TFUEL</p>
                        </div>
                        <div className="col-lg-12 col-md-12 col-12">
                          <div className="grey-border"></div>
                        </div>
                      </>
                    )}
                    {itemCtx.darkCount > 0 && (
                      <>
                        <div className="col-lg-3 col-md-3 col-3">
                          <p className="tt-font">Pokerchip:</p>
                        </div>
                        <div className="col-lg-3 col-md-3 col-3">
                          <p className="tt-font">Dark</p>
                        </div>
                        <div className="col-lg-3 col-md-3 col-3 text-right">
                          <p className="tt-font">x{itemCtx.darkCount}</p>
                        </div>
                        <div className="col-lg-3 col-md-3 col-3 text-right">
                          <p className="tt-font">{renderPrices()} TFUEL</p>
                        </div>
                        <div className="col-lg-12 col-md-12 col-12">
                          <div className="grey-border"></div>
                        </div>
                      </>
                    )}

                    {itemCtx.tDropCountK > 0 && (
                      <>
                        <div className="col-lg-3 col-md-3 col-3">
                          <p className="tt-font">Key Chain:</p>
                        </div>
                        <div className="col-lg-3 col-md-3 col-3">
                          <p className="tt-font">Tdrop</p>
                        </div>
                        <div className="col-lg-3 col-md-3 col-3 text-right">
                          <p className="tt-font">x{itemCtx.tDropCountK}</p>
                        </div>
                        <div className="col-lg-3 col-md-3 col-3 text-right">
                          <p className="tt-font">{renderPrices()} TFUEL</p>
                        </div>
                        <div className="col-lg-12 col-md-12 col-12">
                          <div className="grey-border"></div>
                        </div>
                      </>
                    )}

                    {itemCtx.tFuelCountK > 0 && (
                      <>
                        <div className="col-lg-3 col-md-3 col-3">
                          <p className="tt-font">Key Chain:</p>
                        </div>
                        <div className="col-lg-3 col-md-3 col-3">
                          <p className="tt-font">Tfuel</p>
                        </div>
                        <div className="col-lg-3 col-md-3 col-3 text-right">
                          <p className="tt-font">x{itemCtx.tFuelCountK}</p>
                        </div>
                        <div className="col-lg-3 col-md-3 col-3 text-right">
                          <p className="tt-font">{renderPrices()} TFUEL</p>
                        </div>
                        <div className="col-lg-12 col-md-12 col-12">
                          <div className="grey-border"></div>
                        </div>
                      </>
                    )}

                    {itemCtx.godzillaCountK > 0 && (
                      <>
                        <div className="col-lg-3 col-md-3 col-3">
                          <p className="tt-font">Key Chain:</p>
                        </div>
                        <div className="col-lg-3 col-md-3 col-3">
                          <p className="tt-font">Godzilla</p>
                        </div>
                        <div className="col-lg-3 col-md-3 col-3 text-right">
                          <p className="tt-font">x{itemCtx.godzillaCountK}</p>
                        </div>
                        <div className="col-lg-3 col-md-3 col-3 text-right">
                          <p className="tt-font">{renderPrices()} TFUEL</p>
                        </div>
                        <div className="col-lg-12 col-md-12 col-12">
                          <div className="grey-border"></div>
                        </div>
                      </>
                    )}

                    {itemCtx.blackCountK > 0 && (
                      <>
                        <div className="col-lg-3 col-md-3 col-3">
                          <p className="tt-font">Key Chain:</p>
                        </div>
                        <div className="col-lg-3 col-md-3 col-3">
                          <p className="tt-font">Black</p>
                        </div>
                        <div className="col-lg-3 col-md-3 col-3 text-right">
                          <p className="tt-font">x{itemCtx.blackCountK}</p>
                        </div>
                        <div className="col-lg-3 col-md-3 col-3 text-right">
                          <p className="tt-font">{renderPrices()} TFUEL</p>
                        </div>
                        <div className="col-lg-12 col-md-12 col-12">
                          <div className="grey-border"></div>
                        </div>
                      </>
                    )}
                    {itemCtx.blueCountK > 0 && (
                      <>
                        <div className="col-lg-3 col-md-3 col-3">
                          <p className="tt-font">Key Chain:</p>
                        </div>
                        <div className="col-lg-3 col-md-3 col-3">
                          <p className="tt-font">Blue</p>
                        </div>
                        <div className="col-lg-3 col-md-3 col-3 text-right">
                          <p className="tt-font">x{itemCtx.blueCountK}</p>
                        </div>
                        <div className="col-lg-3 col-md-3 col-3 text-right">
                          <p className="tt-font">{renderPrices()}TFUEL</p>
                        </div>
                        <div className="col-lg-12 col-md-12 col-12">
                          <div className="grey-border"></div>
                        </div>
                      </>
                    )}
                    {itemCtx.darkCountK > 0 && (
                      <>
                        <div className="col-lg-3 col-md-3 col-3">
                          <p className="tt-font">Key Chain:</p>
                        </div>
                        <div className="col-lg-3 col-md-3 col-3">
                          <p className="tt-font">Dark</p>
                        </div>
                        <div className="col-lg-3 col-md-3 col-3 text-right">
                          <p className="tt-font">x{itemCtx.darkCountK}</p>
                        </div>
                        <div className="col-lg-3 col-md-3 col-3 text-right">
                          <p className="tt-font">{renderPrices()} TFUEL</p>
                        </div>
                        <div className="col-lg-12 col-md-12 col-12">
                          <div className="grey-border"></div>
                        </div>
                      </>
                    )}

                    <div className="col-lg-12 mt-4 mb-3 text-right">
                      <p className="tt-font grey-color">
                        Shipping Cost:
                        <span className="text-white">{postagePrice} TFUEL</span>
                      </p>
                      <p className="tt-font grey-color">
                        Total Cost:
                        <span className="text-white">{price} TFUEL</span>
                      </p>
                    </div>

                    <div className="col-lg-12  col-md-12 col-12">
                      <div className="form-group">
                        <div className="row">
                          <div className="col-lg-12">
                            <label className="label-modal grey-color tt-font">
                              POSTAL ADDRESS
                            </label>
                          </div>
                        </div>

                        <input
                          type="text"
                          name="address"
                          className="p-3 form-control bg-dark text-white tt-font border-none"
                          onChange={(e) => {
                            setPostalAddress(e.target.value);
                          }}
                          value={postalAddress}
                        />
                        <div className="row">
                          <div className="col-lg-12">
                            <label className="label-modal grey-color tt-font">
                              EMAIL ADDRESS
                            </label>
                          </div>
                        </div>

                        <input
                          type="email"
                          name="address"
                          className="p-3 form-control bg-dark text-white tt-font border-none"
                          onChange={(e) => {
                            setEmailAddress(e.target.value);
                          }}
                          value={emailAddress}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12  col-md-12 col-12 mt-3">
                      <button
                        className="buy-btn btn tt-font-bold w-100 p-3"
                        value="Confirm"
                        onClick={confirmHandler}
                      >
                        {isLoading ? <CircularProgress /> : "Confirm"}
                      </button>
                    </div>
                  </>
                )}
                {!isPurchase && (
                  <>
                    <h10 style={{ marginBottom: "1rem" }}>
                      How to Purchase Premium Ceramic Poker chips & Keychains
                    </h10>
                    <h10 style={{ marginBottom: "1rem" }}>
                      Note: Shipping is currently is a flat rate of 100 TFUEL
                      and is automatically added to the order.
                    </h10>
                    <ol style={{ marginLeft: "1rem" }}>
                      <li style={{ marginBottom: "0.5rem" }}>
                        Connect your Metamask TFUEL wallet to top right of
                        webpage: How to set up Metamask
                        <a
                          href="https://medium.com/theta-network/theta-blockhain-now-accessible-through-metamask-plug-in-61b278633264"
                          target={"_blank"}
                        >
                          {" "}
                          https://medium.com/theta-network/theta-blockhain-now-accessible-through-metamask-plug-in-61b278633264
                        </a>
                      </li>
                      <li style={{ marginBottom: "0.5rem" }}>
                        Select “Buy PokerChip / Keychain” tab on top right of
                        “Token 2021 Collection” page
                      </li>
                      <li style={{ marginBottom: "0.5rem" }}>
                        Select poker chip or keychain then select type from drop
                        down list to right
                      </li>
                      <li style={{ marginBottom: "0.5rem" }}>
                        Change Pokerchip selection from drop down and Add
                        another item to order
                      </li>
                      <li style={{ marginBottom: "0.5rem" }}>
                        Enter Email and postal address and confirm
                      </li>
                      <li style={{ marginBottom: "0.5rem" }}>
                        Once you have clicked confirm be patient and mind not to
                        confirm any double orders on your Metamask wallet, if
                        you have clicked on the confirm button more than once
                        during the ordering process
                      </li>
                      <li style={{ marginBottom: "0.5rem" }}>
                        Once confirmed I will email with order confirmation and
                        dispatch updates
                      </li>
                    </ol>
                    <h10>Thank you for your support.</h10>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Tokencollection;
